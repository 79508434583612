// This file is generated automatically, do not make manual changes to it.
// generating script: shell/generate_permissions.go

export const CAN_ADD_BANK_FAIL_REASONS = 'core-CanAddBankFailReasons'
export const CAN_ADD_GRACE_PERIOD = 'core-CanAddGracePeriod'
export const CAN_ADD_PAYMENTS = 'core-CanAddPayments'
export const CAN_ADD_SUBSCRIPTION = 'core-CanAddSubscription'
export const CAN_ADD_SUBSCRIPTION_SERVICE = 'core-CanAddSubscriptionService'
export const CAN_CANCEL_PAYMENTS = 'core-CanCancelPayments'
export const CAN_DELETE_ACCOUNT_BLACK_LIST = 'core-CanDeleteAccountBlackList'
export const CAN_DELETE_ACTIVE_SUBSCRIPTION = 'core-CanDeleteActiveSubscription'
export const CAN_DELETE_BANK_FAIL_REASONS = 'core-CanDeleteBankFailReasons'
export const CAN_DELETE_GRACE_PERIOD = 'core-CanDeleteGracePeriod'
export const CAN_EDIT_ACCOUNT_BLACK_LIST = 'core-CanEditAccountBlackList'
export const CAN_EDIT_ADDITIONAL_CHARGE = 'core-CanEditAdditionalCharge'
export const CAN_EDIT_BANK_FAIL_REASONS = 'core-CanEditBankFailReasons'
export const CAN_EDIT_BANKS = 'core-CanEditBanks'
export const CAN_EDIT_COMPANIES = 'core-CanEditCompanies'
export const CAN_EDIT_COMPANY_BANK_ACCOUNTS = 'core-CanEditCompanyBankAccounts'
export const CAN_EDIT_COMPANY_PAYMENT_SERVICES = 'core-CanEditCompanyPaymentServices'
export const CAN_EDIT_GRACE_PERIOD = 'core-CanEditGracePeriod'
export const CAN_EDIT_INSTALLATION = 'core-CanEditInstallation'
export const CAN_EDIT_INSTALLATION_EQUIPMENTS = 'core-CanEditInstallationEquipments'
export const CAN_EDIT_PAYMENT_INVOICES = 'core-CanEditPaymentInvoices'
export const CAN_EDIT_PAYMENTS = 'core-CanEditPayments'
export const CAN_EDIT_PERSON_BANK_ACCOUNTS = 'core-CanEditPersonBankAccounts'
export const CAN_EDIT_PERSON_PAYMENT_METHODS = 'core-CanEditPersonPaymentMethods'
export const CAN_EDIT_PERSON_PAYMENT_SERVICES = 'core-CanEditPersonPaymentServices'
export const CAN_EDIT_RECEIVERS = 'core-CanEditReceivers'
export const CAN_EDIT_SUBSCRIPTION = 'core-CanEditSubscription'
export const CAN_EDIT_SUBSCRIPTION_PRICES = 'core-CanEditSubscriptionPrices'
export const CAN_EDIT_SUBSCRIPTION_SERVICE = 'core-CanEditSubscriptionService'
export const CAN_GENERATE_SEPA = 'core-CanGenerateSEPA'
export const CAN_PROCESS_INSTALLATION = 'core-CanProcessInstallation'
export const CAN_VIEW_ACCOUNT_BLACK_LIST = 'core-CanViewAccountBlackList'
export const CAN_VIEW_ADDITIONAL_CHARGE = 'core-CanViewAdditionalCharge'
export const CAN_VIEW_BANK_FAIL_REASONS = 'core-CanViewBankFailReasons'
export const CAN_VIEW_BANKS = 'core-CanViewBanks'
export const CAN_VIEW_COMPANIES = 'core-CanViewCompanies'
export const CAN_VIEW_COMPANY_BANK_ACCOUNTS = 'core-CanViewCompanyBankAccounts'
export const CAN_VIEW_COMPANY_PAYMENT_SERVICES = 'core-CanViewCompanyPaymentServices'
export const CAN_VIEW_GRACE_PERIOD = 'core-CanViewGracePeriod'
export const CAN_VIEW_INSTALLATION = 'core-CanViewInstallation'
export const CAN_VIEW_PAYMENT_INVOICES = 'core-CanViewPaymentInvoices'
export const CAN_VIEW_PAYMENTS = 'core-CanViewPayments'
export const CAN_VIEW_PERSON_BANK_ACCOUNTS = 'core-CanViewPersonBankAccounts'
export const CAN_VIEW_PERSON_PAYMENT_METHODS = 'core-CanViewPersonPaymentMethods'
export const CAN_VIEW_PERSON_PAYMENT_SERVICES = 'core-CanViewPersonPaymentServices'
export const CAN_VIEW_RECEIVERS = 'core-CanViewReceivers'
export const CAN_VIEW_SUBSCRIPTION = 'core-CanViewSubscription'
export const CAN_VIEW_SUBSCRIPTION_SERVICE = 'core-CanViewSubscriptionService'

const coreServer = [
  {
    value: CAN_ADD_BANK_FAIL_REASONS,
    description: CAN_ADD_BANK_FAIL_REASONS,
  },
  {
    value: CAN_ADD_GRACE_PERIOD,
    description: CAN_ADD_GRACE_PERIOD,
  },
  {
    value: CAN_ADD_PAYMENTS,
    description: CAN_ADD_PAYMENTS,
  },
  {
    value: CAN_ADD_SUBSCRIPTION,
    description: CAN_ADD_SUBSCRIPTION,
  },
  {
    value: CAN_ADD_SUBSCRIPTION_SERVICE,
    description: CAN_ADD_SUBSCRIPTION_SERVICE,
  },
  {
    value: CAN_CANCEL_PAYMENTS,
    description: CAN_CANCEL_PAYMENTS,
  },
  {
    value: CAN_DELETE_ACCOUNT_BLACK_LIST,
    description: CAN_DELETE_ACCOUNT_BLACK_LIST,
  },
  {
    value: CAN_DELETE_ACTIVE_SUBSCRIPTION,
    description: CAN_DELETE_ACTIVE_SUBSCRIPTION,
  },
  {
    value: CAN_DELETE_BANK_FAIL_REASONS,
    description: CAN_DELETE_BANK_FAIL_REASONS,
  },
  {
    value: CAN_DELETE_GRACE_PERIOD,
    description: CAN_DELETE_GRACE_PERIOD,
  },
  {
    value: CAN_EDIT_ACCOUNT_BLACK_LIST,
    description: CAN_EDIT_ACCOUNT_BLACK_LIST,
  },
  {
    value: CAN_EDIT_ADDITIONAL_CHARGE,
    description: CAN_EDIT_ADDITIONAL_CHARGE,
  },
  {
    value: CAN_EDIT_BANK_FAIL_REASONS,
    description: CAN_EDIT_BANK_FAIL_REASONS,
  },
  {
    value: CAN_EDIT_BANKS,
    description: CAN_EDIT_BANKS,
  },
  {
    value: CAN_EDIT_COMPANIES,
    description: CAN_EDIT_COMPANIES,
  },
  {
    value: CAN_EDIT_COMPANY_BANK_ACCOUNTS,
    description: CAN_EDIT_COMPANY_BANK_ACCOUNTS,
  },
  {
    value: CAN_EDIT_COMPANY_PAYMENT_SERVICES,
    description: CAN_EDIT_COMPANY_PAYMENT_SERVICES,
  },
  {
    value: CAN_EDIT_GRACE_PERIOD,
    description: CAN_EDIT_GRACE_PERIOD,
  },
  {
    value: CAN_EDIT_INSTALLATION,
    description: CAN_EDIT_INSTALLATION,
  },
  {
    value: CAN_EDIT_INSTALLATION_EQUIPMENTS,
    description: CAN_EDIT_INSTALLATION_EQUIPMENTS,
  },
  {
    value: CAN_EDIT_PAYMENT_INVOICES,
    description: CAN_EDIT_PAYMENT_INVOICES,
  },
  {
    value: CAN_EDIT_PAYMENTS,
    description: CAN_EDIT_PAYMENTS,
  },
  {
    value: CAN_EDIT_PERSON_BANK_ACCOUNTS,
    description: CAN_EDIT_PERSON_BANK_ACCOUNTS,
  },
  {
    value: CAN_EDIT_PERSON_PAYMENT_METHODS,
    description: CAN_EDIT_PERSON_PAYMENT_METHODS,
  },
  {
    value: CAN_EDIT_PERSON_PAYMENT_SERVICES,
    description: CAN_EDIT_PERSON_PAYMENT_SERVICES,
  },
  {
    value: CAN_EDIT_RECEIVERS,
    description: CAN_EDIT_RECEIVERS,
  },
  {
    value: CAN_EDIT_SUBSCRIPTION,
    description: CAN_EDIT_SUBSCRIPTION,
  },
  {
    value: CAN_EDIT_SUBSCRIPTION_PRICES,
    description: CAN_EDIT_SUBSCRIPTION_PRICES,
  },
  {
    value: CAN_EDIT_SUBSCRIPTION_SERVICE,
    description: CAN_EDIT_SUBSCRIPTION_SERVICE,
  },
  {
    value: CAN_GENERATE_SEPA,
    description: CAN_GENERATE_SEPA,
  },
  {
    value: CAN_PROCESS_INSTALLATION,
    description: CAN_PROCESS_INSTALLATION,
  },
  {
    value: CAN_VIEW_ACCOUNT_BLACK_LIST,
    description: CAN_VIEW_ACCOUNT_BLACK_LIST,
  },
  {
    value: CAN_VIEW_ADDITIONAL_CHARGE,
    description: CAN_VIEW_ADDITIONAL_CHARGE,
  },
  {
    value: CAN_VIEW_BANK_FAIL_REASONS,
    description: CAN_VIEW_BANK_FAIL_REASONS,
  },
  {
    value: CAN_VIEW_BANKS,
    description: CAN_VIEW_BANKS,
  },
  {
    value: CAN_VIEW_COMPANIES,
    description: CAN_VIEW_COMPANIES,
  },
  {
    value: CAN_VIEW_COMPANY_BANK_ACCOUNTS,
    description: CAN_VIEW_COMPANY_BANK_ACCOUNTS,
  },
  {
    value: CAN_VIEW_COMPANY_PAYMENT_SERVICES,
    description: CAN_VIEW_COMPANY_PAYMENT_SERVICES,
  },
  {
    value: CAN_VIEW_GRACE_PERIOD,
    description: CAN_VIEW_GRACE_PERIOD,
  },
  {
    value: CAN_VIEW_INSTALLATION,
    description: CAN_VIEW_INSTALLATION,
  },
  {
    value: CAN_VIEW_PAYMENT_INVOICES,
    description: CAN_VIEW_PAYMENT_INVOICES,
  },
  {
    value: CAN_VIEW_PAYMENTS,
    description: CAN_VIEW_PAYMENTS,
  },
  {
    value: CAN_VIEW_PERSON_BANK_ACCOUNTS,
    description: CAN_VIEW_PERSON_BANK_ACCOUNTS,
  },
  {
    value: CAN_VIEW_PERSON_PAYMENT_METHODS,
    description: CAN_VIEW_PERSON_PAYMENT_METHODS,
  },
  {
    value: CAN_VIEW_PERSON_PAYMENT_SERVICES,
    description: CAN_VIEW_PERSON_PAYMENT_SERVICES,
  },
  {
    value: CAN_VIEW_RECEIVERS,
    description: CAN_VIEW_RECEIVERS,
  },
  {
    value: CAN_VIEW_SUBSCRIPTION,
    description: CAN_VIEW_SUBSCRIPTION,
  },
  {
    value: CAN_VIEW_SUBSCRIPTION_SERVICE,
    description: CAN_VIEW_SUBSCRIPTION_SERVICE,
  },
]

export default coreServer
