import { RouteConfig } from 'vue-router/types/router'
import { CAN_ACCESS_SERVICES } from '@/permissions/services'
import { ROUTER_PARAM_ID } from '@/utils/constants'

export const ROUTE_SERVICES_SETTINGS = 'ROUTE_SERVICES_SETTINGS'
export const ROUTE_SERVICE_SETTINGS_FORM = 'ROUTE_SERVICE_SETTINGS_FORM'
export const ROUTE_SERVICE_SETTINGS_VIEW = 'ROUTE_SERVICE_SETTINGS_VIEW'

export const ROUTE_SERVICES_CRED = 'ROUTE_SERVICES_CRED'
export const ROUTE_SERVICES_CRED_FORM = 'ROUTE_SERVICES_CRED_FORM'
export const ROUTE_SERVICES_CRED_VIEW = 'ROUTE_SERVICES_CRED_VIEW'

export const ROUTE_SERVICES = 'ROUTE_SERVICES'
export const ROUTE_SERVICES_ORPHANS = 'ROUTE_SERVICES_ORPHANS'
export const ROUTE_SERVICES_MULTI_ACTIVE = 'ROUTE_SERVICES_MULTI_ACTIVE'
export const ROUTE_CONTRACT_MATCHING = 'ROUTE_CONTRACT_MATCHING'

const serviceRoutes: RouteConfig[] = [
  {
    name: ROUTE_SERVICES_SETTINGS,
    path: '/services/settings',
    meta: { permission: CAN_ACCESS_SERVICES },
    component: () =>
      import(/* webpackChunkName: "services" */ '../components/services/ServiceSettingsList.vue'),
  },
  {
    name: ROUTE_SERVICE_SETTINGS_FORM,
    path: `/services/settings/:${ROUTER_PARAM_ID}(\\d+)?/form`,
    meta: { permission: CAN_ACCESS_SERVICES },
    component: () =>
      import(/* webpackChunkName: "services" */ '../components/services/ServiceSettingsEdit.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },
  {
    name: ROUTE_SERVICE_SETTINGS_VIEW,
    path: `/services/settings/:${ROUTER_PARAM_ID}(\\d+)`,
    meta: { permission: CAN_ACCESS_SERVICES },
    component: () =>
      import(/* webpackChunkName: "services" */ '../components/services/ServiceSettingsView.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },

  {
    name: ROUTE_SERVICES_CRED,
    path: '/services/credentials',
    meta: { permission: CAN_ACCESS_SERVICES },
    component: () =>
      import(/* webpackChunkName: "services" */ '../components/services/ServiceCredList.vue'),
  },
  {
    name: ROUTE_SERVICES_CRED_FORM,
    path: `/services/credentials/:${ROUTER_PARAM_ID}(\\d+)?/form`,
    meta: { permission: CAN_ACCESS_SERVICES },
    component: () =>
      import(/* webpackChunkName: "services" */ '../components/services/ServiceCredEdit.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },
  {
    name: ROUTE_SERVICES_CRED_VIEW,
    path: `/services/credentials/:${ROUTER_PARAM_ID}(\\d+)`,
    meta: { permission: CAN_ACCESS_SERVICES },
    component: () =>
      import(/* webpackChunkName: "services" */ '../components/services/ServiceCredView.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },

  {
    name: ROUTE_SERVICES,
    path: '/services',
    meta: { permission: CAN_ACCESS_SERVICES },
    component: () =>
      import(/* webpackChunkName: "services" */ '../components/services/ServiceList.vue'),
  },
  {
    name: ROUTE_SERVICES_ORPHANS,
    path: `/services/orphans`,
    meta: { permission: CAN_ACCESS_SERVICES },
    component: () =>
      import(/* webpackChunkName: "services" */ '../components/services/ServiceList.vue'),
    props: () => ({ isOrphans: true }),
  },
  {
    name: ROUTE_SERVICES_MULTI_ACTIVE,
    path: `/services/multi-active`,
    meta: { permission: CAN_ACCESS_SERVICES },
    component: () =>
      import(/* webpackChunkName: "services" */ '../components/services/ServiceList.vue'),
    props: () => ({ isMultiActive: true }),
  },
  {
    name: ROUTE_CONTRACT_MATCHING,
    path: `/services/contract-matching`,
    meta: { permission: CAN_ACCESS_SERVICES },
    component: () =>
      import(/* webpackChunkName: "services" */ '../components/services/ContractMatching.vue'),
    props: () => ({ isMultiActive: true }),
  },
]

export default serviceRoutes
