import { ROUTE_CONTRACT_REDIRECT_TO_PERSON, ROUTE_CUSTOMER_BOARD } from '@/router/persons'
import { ROUTER_PARAM_ID } from '@/utils/constants'
import router from '@/router'
import { ROUTE_GRACE_PERIOD_VIEW } from '@/router/subscriptions'

export function personViewHref(id: number): string {
  const res = router.resolve({
    name: ROUTE_CUSTOMER_BOARD,
    params: {
      [ROUTER_PARAM_ID]: id.toString(),
    },
  })

  return res.href
}

export function contractToPersonRedirectHref(contractID: number): string {
  return router.resolve(contractToPersonRoute(contractID)).href
}

export function contractToPersonRoute(contractID: number) {
  return {
    name: ROUTE_CONTRACT_REDIRECT_TO_PERSON,
    params: {
      [ROUTER_PARAM_ID]: contractID.toString(),
    },
  }
}

export function gracePeriodViewHref(id: number): string {
  const res = router.resolve({
    name: ROUTE_GRACE_PERIOD_VIEW,
    params: {
      [ROUTER_PARAM_ID]: id.toString(),
    },
  })

  return res.href
}
